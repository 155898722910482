import styled from "styled-components";

export const TicketHeaderDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    padding: 175px 20px 40px;
    width: 100%;
    background-image: ${(props) => {
      if (props.background) {
        return `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${props.background});`;
      }
      return "linear-gradient(var(--bg-color), var(--bg-color));";
    }}
    background-size: cover;
    min-height: 872px;
    color: var(--text-color);
    > h2 {
        text-align: center;
    }
    @media (max-width: 768px) {
      min-height: unset;
        > h2 {
            font-size: 32px;
        }
    }
`;

export const TicketInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 35px;
  width: 100%;
  min-height: 300px;
  max-width: 820px;
  padding: 60px 75px 50px;
  background-color: var(--bg-color);
  > h5 {
    font-weight: 400;
    p {
      text-align: center;
    }
    > p:first-of-type {
      margin: 0;
    }
  }
  @media (max-width: 768px) {
    padding: 30px;
    > h5 {
      font-size: 16px;
    }
  }
`;

export const TicketInfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: ${(prop) => (prop?.center ? "center" : "space-between")};
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const TicketInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: ${(prop) => (prop?.center ? "center" : "flex-start")};
  width: 310px;
  > h5 {
    color: var(--slate);
    display: ${(prop) => (prop?.center ? "none" : "block")};
  }
  > h4 {
    margin-bottom: 5px;
  }
  > h2 {
    color: var(--primary);
  }
  > h5,
  > h2,
  > h4 {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    text-align: center;
    > h4 {
      font-size: 21px;
    }

    > h2 {
      font-size: 28px;
    }
  }
`;

export const TicketButton = styled.button`
  font-family: inherit;
  padding: 5px 24px;
  color: var(--btn-text);
  background-color: var(--btn-bg);
  @media (max-width: 768px) {
    > h4 {
      font-size: 18px;
    }
  }
`;

export const ShareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: var(--primary);
`;
