import React from "react";
import Layout from "../components/Layout";
import { graphql, useStaticQuery } from "gatsby";
import { ShareWrapper, TicketButton, TicketHeaderDiv, TicketInfo, TicketInfoDiv, TicketInfoHeader } from "../components/TicketStyles/element";
import { H2, H2SmallStrong, H3, H4, H4BigStrong, H4Strong, H5BigMedium, H5SmallStrong, H6 } from "../components/Typography";
import { AnimatedButton } from "../components/Buttons/elements";
import MiscSocialSmall from "../components/icons/MiscSocialSmall";
import HomepageInfo from "../components/HomepageInfo";
import HomepageMusic from "../components/HomepageMusic";
import HomepageParticipants from "../components/HomepageParticipants";
import HomepageLastSection from "../components/HomepageLastSection";

const TicketPage = () => {
  const ticketQuery = useStaticQuery(graphql`
    query {
      craftQafTicketLandingPageQafTicketLandingPageEntry {
        showTicketInfo
        showTicketCounter
        showTicketPerformer
        showTicketParticipants
        showTicketLastSection
        showSocialShareButton
        pageTitle
        titleSeo
        ticketButtonText
        ticketButtonLink
        headerBackgroundImage {
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(width: 1440, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(width: 1440, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        ticketPrice {
          ... on Craft_ticketPrice_ticketPrice_BlockType {
            id
            ticketSaleTime
            ticketDescription
            ticketCode
          }
        }
        ticketAnnoucement
      }
      craftQafHomepageQafHomepageEntry {
        lastSectionBottomButtonLink
        lastSectionBottomButtonText
        lastSectionDescription
        lastSectionTitle
        lastSectionTopButtonLink
        lastSectionTopButtonText
        performerTitle: homepagePerformersTitle
        counterSection {
          title
          number
        }
        musicEvent {
          id
          ... on Craft_qafEvents_qafEvent_Entry {
            id
            title
            eventStartDateTime
            eventEndDateTime
            slug
            qafEventLocation {
              title
            }
            headerImage: qafEventHeaderImage {
              ... on Craft_eventsAssets_Asset {
                id
                localFile {
                  childImageSharp {
                    fixed(quality: 100, width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        performerButton: performersButtonText
        performerBackground: performersBackgroundImage {
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(width: 1440, fit: COVER, quality: 100) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(width: 1440, fit: COVER, quality: 100) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        participantsTitle: participantsSectionTitle
        participantsButton: participantsSectionButtonText
        participantsBackground: participantsBackgroundImage {
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 1440) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 1440) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
      craftQafProgrammeQafProgrammeEntry {
        allowDownloadProgramme
        pdfProgrammeFile {
          id
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              publicURL
            }
          }
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              publicURL
            }
          }
        }
      }
      participants: allCraftParticipantsDefaultEntry(filter: { isDraft: { eq: false } }) {
        nodes {
          id
          communityMemberSlug
          qafParticipantType {
            ... on Craft_qafParticipantType_default_Entry {
              id
              title
            }
          }
          communityMemberWebsite
          communityMemberEmailAddress
          subtitle
          communityMemberOpeningHours
          participantLocation {
            title
          }
          communityMemberDescription
          communityMemberContactDetails {
            method
            value
          }
          slug
          title
          headerImage: qafParticipantHeaderImage {
            ... on Craft_communityAssets_Asset {
              localFile {
                publicURL
                childImageSharp {
                  fixed(quality: 100, height: 590) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            ... on Craft_eventsAssets_Asset {
              localFile {
                publicURL
                childImageSharp {
                  fixed(quality: 100, height: 590) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const data = ticketQuery?.craftQafTicketLandingPageQafTicketLandingPageEntry;
  const homepageData = ticketQuery?.craftQafHomepageQafHomepageEntry;
  const attchmentData = ticketQuery?.craftQafProgrammeQafProgrammeEntry;
  const participantsData = ticketQuery?.participants?.nodes;

  const {
    titleSeo,
    descriptionSeo,
    pageTitle,
    headerBackgroundImage,
    ticketPrice,
    ticketAnnoucement,
    ticketButtonText,
    ticketButtonLink,
    showTicketInfo,
    showTicketCounter,
    showTicketPerformer,
    showTicketParticipants,
    showTicketLastSection,
    showSocialShareButton
  } = data;

  const {
    counterSection,
    musicEvent,
    performerTitle,
    performerButton,
    participantsTitle,
    participantsButton,
    participantsBackground,
    lastSectionBottomButtonLink,
    lastSectionBottomButtonText,
    lastSectionDescription,
    lastSectionTitle,
    lastSectionTopButtonLink,
    lastSectionTopButtonText,
  } = homepageData;
  const performerBackground = homepageData?.performerBackground?.[0]?.localFile?.childImageSharp?.fixed?.src || null;
  const { allowDownloadProgramme, pdfProgrammeFile } = attchmentData;

  return (
    <Layout skipHeader headerBlack backgroundColor={"var(--bg-color)"} title={titleSeo ?? "Ticket - Quoz Arts Fest"} SEODescription={descriptionSeo}>
      <TicketHeaderDiv background={headerBackgroundImage?.[0]?.localFile?.childImageSharp?.fixed?.src}>
        {pageTitle ? <H2>{pageTitle}</H2> : null}
        <TicketInfoDiv>
          {showTicketInfo && ticketPrice?.length ? (
            <>
              <TicketInfoHeader center={ticketPrice?.length === 1}>
                {ticketPrice?.map((price) => (
                  <TicketInfo key={price?.id} center={ticketPrice?.length === 1}>
                    <H5SmallStrong>{price?.ticketSaleTime}</H5SmallStrong>
                    <H4BigStrong>{price?.ticketDescription}</H4BigStrong>
                    <H2SmallStrong>{price?.ticketCode}</H2SmallStrong>
                  </TicketInfo>
                ))}
              </TicketInfoHeader>
              {ticketAnnoucement ? (
                <H5BigMedium
                  dangerouslySetInnerHTML={{
                    __html: ticketAnnoucement,
                  }}
                ></H5BigMedium>
              ) : null}
              <AnimatedButton style={{ margin: "0 auto" }}>
                <a href={ticketButtonLink}>
                  <TicketButton>
                    <H4>{ticketButtonText}</H4>
                  </TicketButton>
                </a>
              </AnimatedButton>
              <ShareWrapper>
                <H5SmallStrong>SHARE</H5SmallStrong>
                <MiscSocialSmall color={"var(--primary)"} customOnOff={true} listSocialShow={showSocialShareButton} />
              </ShareWrapper>
            </>
          ) : (
            <H3 style={{textAlign: "center"}}>Coming Soon</H3>
          )}
        </TicketInfoDiv>
      </TicketHeaderDiv>
      {showTicketCounter && counterSection?.length ? <HomepageInfo counterList={counterSection} /> : null}
      {musicEvent?.length > 0 && showTicketPerformer ? (
        <HomepageMusic events={musicEvent} title={performerTitle || "SHOW ALL"} buttonText={performerButton} background={performerBackground} />
      ) : null}
      {showTicketParticipants ? (
        <HomepageParticipants
          participants={participantsData}
          title={participantsTitle}
          buttonText={participantsButton}
          background={participantsBackground?.[0]?.localFile?.childImageSharp?.fixed?.src}
        />
      ) : null}
      {showTicketLastSection ? (
        <HomepageLastSection
          lastSectionBottomButtonLink={lastSectionBottomButtonLink}
          lastSectionBottomButtonText={lastSectionBottomButtonText}
          lastSectionDescription={lastSectionDescription}
          lastSectionTitle={lastSectionTitle}
          lastSectionTopButtonLink={lastSectionTopButtonLink}
          lastSectionTopButtonText={lastSectionTopButtonText}
        />
      ) : null}
    </Layout>
  );
};

export default TicketPage;
